export default {
    'title': '隐私政策',
    'dataTime': '生效日期：2024年1月15日',
    'data': {
        'descr': '本隐私政策详细说明了Visbody, Inc.（位于中国陕西省西安市未央区尚华路555号正麒工业园（正麒研发楼5-6楼））的隐私保护政策（以下简称“Visbody”、“我们”、“我们的”）。本隐私政策仅适用于通过位于URL：www.visbody.com的网站收集的信息。如果您对本隐私政策有任何问题，可以通过hello@visbody.com与我们联系。'
    },
    'undefined': ' ',
    'title1-h': '我们收集哪些信息？',
    'title1': [
        '● 当您使用平台时提供给我们的信息，包括您的联系信息，用于访问平台的登录名和密码，使用我们的扫描仪进行的测量信息（以便我们提供洞察或推荐定制解决方案），您链接到Visbody帐户的服务的信息，以及您通过平台与他人交流的相关信息。',
        '● 关于您用于访问平台的计算机和设备的信息，包括它们的位置。',
        '● 关于您使用平台的信息。我们使用cookies，信标和其他跟踪技术来收集此类信息。'
    ],
    'title2-h': '我们如何使用您的个人信息？',
    'title2-h1h': '订单 / 推荐',
    'title2-h1': [
        '● 处理您的订单并满足您的请求。',
        '● 根据您的测量数据推荐产品和服务。',
        '● 向您提供我们的产品和平台。',
        '● 交付您购买的产品。'
    ],
    'title2-h2h': '营销',
    'title2-h2': [
        '● 向您发送电子邮件，通讯和其他营销通信。',
        '● 告诉您我们认为您会感兴趣的优惠。'
    ],
    'title2-h3h': '安全',
    'title2-h3': [
        '● 保护平台的安全。',
        '● 执行我们的条款和条件。',
        '● 进行市场研究和受众分析，这使我们能够了解我们的客户是谁以及他们希望我们提供哪些产品/服务。',
        '● 向我们的商业客户提供某些信息。',
        '● 定制您在平台上看到的内容和广告，并将您识别为先前的访问者。'
    ],
    'title3-h': '我们为何使用您的个人信息？',
    'title3': [
        '● 使我们履行对您的合同义务。',
        '● 当您同意我们使用这些或与我们的业务有关的其他用途时。',
        '● 我们或我们的业务客户从事这些活动有合法的商业利益，因此可能无法获得您的同意。我们会将这种使用方式通知您，告知您的权利，并将我们使用的信息量限制在实现这些目的所需的范围内，从而保护您的权利。'
    ],
    'title4-h': '我们与谁分享您的个人信息？',
    'title4': [
        '● 其他Visbody业务：在适用法律允许的情况下，我们可能会与我们的关联公司分享信息。',
        '● 第三方服务提供商：我们委托其他公司和个人代表我们提供服务。例如包括处理付款，发送电子邮件，数据分析，提供营销帮助以及响应客户服务请求。'
    ],
    'title5-h': '我们会将您的个人信息保留多长时间？',
    'title5': [
        '我们将保留您的信息，直到您要求我们删除它。一旦您要求我们删除您的信息，我们将只保留为履行我们的法律义务或出于我们的合法利益所需的信息。'
    ],
    'title5-h1h': '您的权利和选择',
    'title5-h1': [
        '我们为您提供了选择不接收促销信息和基于兴趣的广告的能力。如果您在欧洲经济区（EEA），您有权：',
        '● 访问我们关于您的信息，并要求我们根据需要进行更正或更新。',
        '● 请求我们提供我们关于您的信息的副本。',
        '● 请求我们删除所掌握的有关于您的信息。',
        '● 接收与他们有关的数据以及由他们提供的数据，，并将这些数据转移到其他提供者/控制器',
        '● 如果认为供应商正在处理与您有关的数据，违反了数据保护规定，您可以向监管机构提出投诉',
        '● 撤回您对处理您的信息的同意，或要求我们限制如何使用您的信息。'
    ],
    'title5-h2h': '广告',
    'title5-h2': [
        '您可以选择是否看到基于您的兴趣的广告。'
    ],
    'title6-h': '了解关于Visbody的隐私政策的更多信息',
    'title6-h1h': '我们收集哪些信息？',
    'title6-h1': [
        '我们收集您自愿提供的信息，以及您的计算机，手机，平板电脑，游戏机或其他设备（统称为“设备”）或浏览器自动提供的信息。我们使用cookies和其他跟踪技术使平台为您提供更好的服务，并用于广告目的。您可以选择不使用cookies，但如果您这样做，平台的某些功能将无法正常运行。',
        '您向我们提供的信息：当您使用平台时，我们或我们的服务提供商（代表我们行事）会收集您提供给我们的“个人信息”（这些信息本身或与其他信息结合后可用于识别您的身份），例如您的名字和姓氏，性别，电子邮件地址，位置，电话号码，通讯地址，年龄，出生日期，网站链接，位置信息，体重，生物识别数据以及某些支付卡信息。',
        '我们将在以下情况收集您的信息：',
        '● 注册创建帐户；',
        '● 购买物品；',
        '● 使用我们的产品或服务；',
        '● 参与博客活动；',
        '● 询问平台；',
        '● 接收我们的通讯信息和/或广告；',
        '● 由朋友介绍或通过营销推广活动认识我们。',
        '关于他人的信息：您可能决定向我们提供他人的电子邮件或电话号码，以便我们通过电子邮件或其他消息服务（例如WhatsApp）发送信息。我们使用这些信息来联系，并在必要时提醒他或她已被邀请加入Visbody或通过平台接收内容。',
        '自动收集的信息：每当您访问或与网站交互时，我们以及我们的第三方广告商和/或服务提供商都会使用各种技术自动或被动地收集有关您如何访问和使用网站的信息（“使用信息”）。这种统计使用数据为我们提供了有关网站使用情况的信息，例如有多少访问者访问网站上的特定页面，他们在该页面上停留多长时间，以及他们是否“点击”了任何超链接。这些信息帮助我们保持网站新鲜有趣，为我们的访问者提供定制内容。使用信息包括：',
        '● 您设备的IP地址或其他唯一标识符。',
        '● 设备类型。',
        '● 您正在使用的浏览器软件和操作系统。',
        '● 您已访问的网页以及您访问它们的时间。',
        '● 产品和网络使用日志，用于错误检测和支持',
        'Cookies；像素标签：平台上用于收集使用信息的技术包括但不限于：cookies（用于访问平台时放置在设备上的数据文件），移动分析软件和像素标签（(放置在网页或电子邮件中的透明图形图像，，用于指示页面或电子邮件的浏览情况）。Cookies还可用于将您与社交网络站点关联起来，并且，，并在您选择的情况下，实现您在平台上的活动与您在此类社交网站上的活动之间的互动。我们或我们的供应商可能出于安全目的在您的设备上放置cookies或类似文件，在您使用我们的平台时，方便网站导航和个性化您的体验（例如允许我们根据您的兴趣、偏好、位置或人口统计信息选择最有可能吸引您的内容、广告或优惠）。像素标签可能会告诉您的浏览器从其他服务器获取内容。',
        '要了解如何减少您接收的cookie数量，或删除已经安装在您的浏览器的cookie文件夹中的cookie，请参阅您的浏览器的帮助菜单或其他与您的浏览器相关的说明。您也可以通过访问<a href="https://ico.org.uk/for-the-public/online/cookies" target="_blank">https://ico.org.uk/for-the-public/online/cookies</a>了解更多有关cookie的信息，该网站包含有关cookie的其他有用信息以及如何使用不同类型的浏览器阻止cookie。',
        'Google 分析：我们使用名为“Google Analytics”的工具收集有关您的互联网使用的信息。例如，您访问我们的网站的频率，您访问的页面，以及您在访问我们网站之前可能访问过的其他网站。我们使用Google Analytics来了解网站的使用情况，并帮助我们改进我们的产品和服务。尽管Google Analytics在您的网络浏览器上植入了一个永久的cookie，以便在您下次访问此网站时将您识别为唯一的用户，但该cookie只能由Google使用。要选择退出Google Analytics，请使用Google的选择退出工具：<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout。</a>',
        '禁止跟踪：目前，网站未设计为响应您的浏览器发送的“禁止跟踪”信号。'
    ],
    'title6-h2h': '我们如何使用您的个人信息？',
    'title6-h2': [
        '我们使用我们收集的关于您的信息来为您提供产品和服务，改善您的体验，并执行我们的权利。',
        '我们使用我们从您那里收集的关于您的个人信息：',
        '● 履行我们与您签订合同中的责任。例如：',
        '● 如果您使用平台购买商品或服务，我们将需要使用您的个人信息来处理您的订单并实现交付；',
        '● 我们将根据扫描目的使用您的身体测量数据（例如，为您提供有关您的健康的见解或向您推荐特定的产品）；',
        '● 我们需要使用您的个人信息来执行我们的仪表板平台或网站使用条款。',
        '● 当我们或我们的商业客户在此类使用中有合法权益时。例如：',
        '● 防止欺诈并保护平台的安全；',
        '● 执行我们的使用条款；',
        '● 传达更新和改进情况；',
        '● 进行市场研究和平台分析；',
        '● 开发和优化我们的产品和服务；',
        '● 向您发送有关我们提供的产品和服务的营销通信，这些产品和服务与您从我们那里购买的产品和服务相似；',
        '● 帮助我们提供和运营平台；',
        '● 向我们的商业客户提供关于消费者使用平台或我们的产品和服务的分析，人工智能，推荐，受众段；',
        '● 遵守我们的法律义务。',
        '● 得到您的同意。例如：',
        '● 我们只在您同意接收它们时向您发送某些营销通信。',
        '● 我们不会在未经您同意的情况下与第三方分享您的个人信息，以用于其营销目的。',
        '● 经您的同意，我们使用您的信息来个性化您的Visbody体验，包括：定制内容，广告和优惠。'
    ],
    'title6-h3h': '我们与谁共享您的信息？',
    'title6-h3': [
        '我们与我们的分销商和服务提供商共享您的信息。我们会在必要时共享个人信息，以行使我们的法律权利或履行我们的法律义务',
        '在以下情况下，我们可能会与第三方共享您的个人信息:',
        '● 分销商：在适用法律允许的情况下，其他Visbody企业(我们的“分销商”)会出于本隐私政策所述目的共同使用您的个人信息。',
        '● 第三方服务提供商：我们使用第三方帮助我们向您提供平台。我们用于平台托管、数据分析、电子邮件、营销、客户服务和其他服务的第三方将访问您的个人信息，以帮助我们向您提供这些服务。当这些第三方服务提供商需要您的个人信息来履行其职责时，我们会将您的个人信息提供给他们。',
        '● 当您同意时。在我们的平台上，您可能有机会选择接收他人的信息和/或营销优惠，或者以其他方式同意与第三方共享您的信息。如果您使用平台的社交共享功能，您的信息可能会与您的某些朋友或群组共享。如果您同意共享您的个人信息，您的个人信息将被披露给第三方，您披露的个人信息将受到第三方的隐私政策和商业实践的约束。',
        '● 出于法律原因需要与第三方合作:我们将在需要时向第三方转让和披露您的个人信息，以符合法律要求或遵守向我们提供的法律程序，确保您遵守我们的使用条款或其他适用政策，调查可能的欺诈行为，或帮助调查或解决与平台相关的任何安全或技术问题。',
        ' '
    ],
    'title6-h4h': '广告',
    'title6-h4': [
        '您可以根据自己的兴趣选择是否观看广告。如果您选择退出，您仍然会看到广告，但广告与您的相关性会降低。',
        'Visbody可能会使用您对平台或我们的产品和服务中获得的信息，以确定我们认为您可能感兴趣的内容，包括来自您可以通过平台或其他方式购买的商品和服务的提供者的内容（他们可能会向我们付费以向您展示此类内容）。请注意，如果第三方内容提供商要求我们向具有某些特征的用户（例如，18-24岁的女性）或该群体的一部分（例如，表示对铁人三项感兴趣的18-24岁的女性）展示内容，并且您响应该内容或点击该内容中嵌入的链接，第三方内容提供商可能会得出结论，认为您具有他们试图接触的受众的特征。Visbody无法访问或控制那些要求我们向您展示内容的第三方可能使用的cookies，网页信标或其他技术，这些第三方的隐私做法不在本隐私政策的涵盖范围内。请直接联系这些公司以获取有关他们的数据收集和隐私政策的信息。有关由NAI成员广告网络提供的定向广告的可用选择退出选项的更多信息，请参见http://www.networkadvertising.org/。',
        '我们可能会使用第三方广告网络提供商来帮助在平台上展示广告，以及其他服务提供商来评估和为我们提供有关使用平台和查看我们内容的信息。这些提供商可能会在您的设备上放置和访问cookies，像素标签或类似技术，为您提供个性化的广告或其他内容，这些内容是根据他们从您在平台和您访问过的其他网站的浏览中推断出的您的兴趣。在这样做的过程中，提供商收集或可以访问到不包括您的标识信息的非个人信息，如您的使用信息。这些提供商使用cookies，像素标签或类似技术受其自身隐私政策的约束，而非我们的隐私政策。',
        '如果您不希望接收定向广告的好处，您可以访问NAI选择退出页面，选择退出使用您的信息的一些网络广告计划，网址为http://www.networkadvertising.org/managing/opt_out.asp或数字广告联盟消费者选择页面www.aboutads.info/choices。',
        '请注意，即使您选择删除您的信息（选择退出），您仍然会在在线浏览时看到广告。然而，您看到的广告可能与您和您的兴趣不太相关。此外，许多网络广告计划允许您查看和管理他们从您的在线浏览活动中编译的兴趣类别。这些兴趣类别有助于确定您可能收到的定向广告类型。NAI选择退出页面和DAA消费者选择页面提供了从那些公司接收cookies的选择退出机制。请注意，如果您选择退出定向广告，出于我们自己的分析、运营和安全目的，我们可能仍然会跟踪您对平台的访问。'
    ],
    'title6-h5h': '儿童',
    'title6-h5': [
        '我们不会有意收集儿童的个人信息。'
    ],
    'title6-h6h': '个人信息的存储',
    'title6-h6': [
        '我们会保留您的信息，直到您要求删除为主。一旦您要求删除您的信息，我们将仅在必要时保留您的信息，以履行我们的法律义务，解决争议，执行我们的协议或采取法律允许的其他行动。',
        '我们通常会存储您的信息，直到您要求我们删除它，或者您的帐户被删除，以先到者为准；但是这个一般规则有一些例外。我们会保留已删除帐户的信息，以遵守法律，防止欺诈，收取费用，解决争议，解决问题，协助调查，处理保修索赔，分发重要的产品信息（如召回信息），执行我们的协议，并采取法律允许的其他行动。',
        '您可以随时通过使用下面提供的联系方式联系我们的数据保护官，请求删除您的个人信息。'
    ],
    'title6-h7h': '安全措施',
    'title6-h7': [
        '我们使用技术和组织安全措施来保护您的信息，但我们不能保证它们会100%有效。',
        '我们使用各种现有的技术和流程，并在物理、技术和管理方面采取保障措施，以保护我们的客户数据。我们在我们的服务器上维护一个私人数据库，用于存储通过平台收集的所有信息。我们的做法是，在接收或传输敏感数据时，我们都会使用加密技术。我们使用加密技术来确保您在使用平台时提供的任何个人信息的安全传输。我们将采取我们认为适当的合理措施来保护您的信息免受丢失，滥用，篡改或破坏，并在可能的情况下，要求我们可能将您的信息转移给的任何第三方采取类似措施来保护该安全。尽管我们会尽一切合理努力来保护您的个人信息的机密性，但我们不能保证您的信息始终是安全的。',
        '电子邮件不是一种安全的通信方式。请不要通过电子邮件向我们发送您的信用卡号，社会保障号或其他个人信息。'
    ],
    'title6-h8h': '您的权利和选择',
    'title6-h8': [
        '我们为您提供退出促销信息和基于兴趣的广告的功能。如果您在欧洲经济区（EEA），您有权访问我们所长收集的关于您的信息，并要求我们根据需要进行更正或更新。您可以按照您收到的任何此类通信中提供的退出说明，停止接收我们的促销通信。您也可以发送电子邮件至hello@visbody.com。',
        '请参阅上文的“广告”部分，了解如何查看和退出某些基于兴趣的广告。',
        '如果您在欧盟，您对我们所持有的您的个人信息享有某些权利：',
        '● 访问。您有权访问我们持有的关于您的个人信息。如果您希望获取我们持有的关于您的个人信息的副本或描述，您可以使用以下提供的联系方式向我们的数据保护官员发送请求（例如，电子邮件或邮寄）。在任何一种情况下，我们可能会要求您验证您的身份，并提供有关您的请求的更多详细信息。',
        '● 准确性。我们会尽最大努力确保我们保留关于您的个人信息是准确的。我们可能会不时向您发送更新电子邮件，提醒您告诉我们关于您个人信息的任何更新或更改。您有权要求任何不准确的个人信息进行更正，任何不完整的信息进行补充，通过使用以下提供的联系方式联系我们的数据保护官。',
        '● 删除和处理限制请求。您有权要求我们删除我们持有的关于您的个人信息。您也有权要求我们停止处理您的个人信息，除非有特定的例外。如果您希望我们删除或停止处理我们持有的关于您的个人信息，请使用以下提供的联系方式联系我们的数据保护官。',
        '● 可移植性请求。您有权要求我们提供您的某些个人信息，或直接将其传输给另一家处理个人信息的公司。如果您希望我们转移您的个人信息，请使用以下提供的联系方式联系我们的数据保护官。',
        '● 撤回接收营销信息的同意。您可以随时要求我们停止使用您的个人信息进行广告或营销目的。如果您希望这样做，请按照您收到的任何通信中的移除指示操作，或者向hello@visbody.com发送电子邮件，并在主题行中写上“取消订阅”。'
    ],
    'title7h': '隐私政策的变更',
    'title7': [
        '我们可能会更改我们的隐私政策，当我们这样做时，我们会更新此页面，请务必定期查看。',
        '我们可能会更新此隐私政策以反映我们对信息惯例的更改。如果我们做出任何重大变更，我们会在更改生效前向您提供明确的通知。我们建议您定期查看此页面，以获取我们最新的隐私政策。'
    ],
    'title8h': '查询或投诉',
    'title8': [
        '如果您对此隐私政策、平台或我们如何使用您的个人信息有任何疑问或疑虑，请使用以下提供的联系方式联系我们的数据保护官。此外，如果您对我们如何使用您的个人信息有任何投诉，您有权向您所在国家的数据保护机构提出投诉。数据保护机构的名单可在http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080查看。'
    ],
    'title9h': '我们的联系方式',
    'title9': [
        '您可以使用以下方式联系我们：',
        '电子邮件：hello@visbody.com',
        '地址：中国陕西省西安市未央区尚华路555号正麒工业园（正麒研发楼5-6层）'
    ]
}
