/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 11:10:56
 * @LastEditors: liutq
 * @LastEditTime: 2024-05-09 18:23:24
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MintUI from 'mint-ui'
import VueDND from 'awe-dnd'
/* element-ui引入 */
import ElementUI from 'element-ui'
import * as echarts from 'echarts';
// import VConsole from 'vconsole';

// new VConsole();
import '@/plugins/element'
import '@/plugins/vant'
import 'mint-ui/lib/style.css'
import '@/assets/styles/main.less'
import '@/assets/styles/theme.less'
import '@/assets/styles/common.less'
import '@/assets/styles/register/public.less'

// GraphQL
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { link } from '@/assets/js/apply-ware.js'

import { getCurrentLang, langPattern } from '@/assets/js/langObject'
window.onload = function() {
  // 阻止双击放大
  var lastTouchEnd = 0;
  document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
          event.preventDefault();
      }
  });
  document.addEventListener('touchend', function(event) {
      var now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
          event.preventDefault();
      }
      lastTouchEnd = now;
  }, false);

  // 阻止双指放大
  document.addEventListener('gesturestart', function(event) {
      event.preventDefault();
  });
};
// Create the apollo client
const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    connectToDevTools: true
})
const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})
// Install the vue plugin
Vue.use(VueApollo)
// lodash js方法库
import lodash from 'lodash'

// moment 做时间处理
import moment from 'moment'

// axios 做跨域请求
import axios from 'axios'

// router and store
import store from './store'

// 多语言
import VueI18n from 'vue-i18n'

import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import zhCN from 'vant/lib/locale/lang/zh-CN'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import esES from 'vant/lib/locale/lang/es-ES'
import frFR from 'vant/lib/locale/lang/fr-FR'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import zhTW from 'vant/lib/locale/lang/zh-TW'
import ruRU from '@/assets/js/lang/ru-RU'
import trTR from 'vant/lib/locale/lang/tr-TR'
import deDE from 'vant/lib/locale/lang/de-DE'
import arAR from '@/assets/js/lang/ar-AR'
import elGR from '@/assets/js/lang/el-GR'

import VueClipBoard from 'vue-clipboard2'


import { getCurrentParam } from '@/assets/js/util'

Vue.prototype.$lodash = Vue.lodash = lodash

Vue.prototype.$moment = Vue.moment = moment

Vue.prototype.$http = Vue.http = axios
axios.defaults.baseURL = '/api' // s所有请求公共的url
Vue.config.productionTip = false

Vue.use(echarts)
Vue.use(MintUI)
Vue.use(VueDND)
Vue.use(VueI18n)
Vue.use(VueClipBoard);
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus

const Language = getCurrentLang()
console.log(Language)
const i18n = new VueI18n({
    locale: Language.lang, // 语言标识
    messages: langPattern
})

function vantLocales (language) {
      if (Language === 'ja-JP') {
        Locale.use('ja-JP', jaJP); 
      } else if ( language === 'zh-CN') {
        Locale.use( 'zh-CN', zhCN)
      } else if ( language === 'es-ES') {
        Locale.use( 'es-ES', esES)
      }else if ( language === 'fr-FR') {
        Locale.use( 'fr-FR', frFR)
      }else if ( language === 'ru-RU') {
        Locale.use( 'ru-RU', ruRU)
      }else if ( language === 'zh-HK') {
        Locale.use( 'zh-HK', zhHK)
      }else if ( language === 'zh-TW') {
        Locale.use( 'zh-TW', zhTW)
      }else  if(language === 'tr-TR'){
        Locale.use( 'tr-TR', trTR)
      }else  if(language === 'de-DE'){
        Locale.use( 'de-DE', deDE)
      }else  if(language === 'ar-AR'){
        Locale.use( 'ar-AR', arAR)
      }else  if(language === 'el-GR'){
        Locale.use( 'el-GR', elGR)
      }else {
        Locale.use('en-US', enUS)
      }
}

console.log("======开始切换语言", i18n.locale)
vantLocales(i18n.locale)

// 初始化默认值
window.localStorage.setItem('lang', Language.lang)
window.localStorage.setItem('unit', window.localStorage.getItem('unit') || 'metric')
// 记住密码时把token放入sessionStorage
if (localStorage.getItem('token')) {
    window.sessionStorage.setItem('token', localStorage.getItem('token'))
}
const deviceType = getCurrentParam()
if (deviceType !== null) {
    const oldType = window.localStorage.getItem('deviceType')
    if (oldType !== deviceType) {
      window.localStorage.setItem('deviceType', deviceType)
      window.localStorage.removeItem('modelInfo')
    } 
}
// 删除缓存的测量信息，强制查询最新的
// window.localStorage.removeItem('modelInfo')
// 设置语言
Vue.use(ElementUI, {
    locale: Language.el,
    i18n: (key, value) => i18n.t(key, value)
})

// router.beforeEach((to, from, next) => {
//   let isAppLogin = sessionStorage.getItem('isAppLogin')
//   /* 浏览器是不是微信浏览器 */
//   const ua = navigator.userAgent.toLowerCase()
//   const isWeixin = ua.indexOf('micromessenger') !== -1
//   // 如果扫码进生成二维码界面，直接放行
//   if (to.path === '/gen-qrcode') {
//       return next()
//   }
//   // 如果从报告首页返回模型加载页面，不跳转
//   if (to.path === '/modeling' && from.path === '/report') {
//       next(false)
//   } else {
//       if (!isWeixin && to.name !== 'exception' && isAppLogin === 0) {
//           next({
//               name: 'exception',
//               query: {
//                   redirect: to.fullPath
//               }
//           })
//       } else {
//           next()
//       }
//   }
// })


/* 设置model-iframe与vue交互的唯一全局变量 */
// eslint-disable-next-line semi-style
;(function(window) {
  window.MODEL_STORE = {}
})(window)

export const vue = new Vue({
  // el: '#app',
  router,
  store,
  i18n,
  provide: apolloProvider.provide(),
  // components: {
  //     App
  // },
  // template: '<App/>'
  render: h => h(App)
}).$mount("#app")
