/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-01 10:42:40
 * @LastEditors: liutq
 * @LastEditTime: 2022-08-12 15:04:33
 */
import Vue from 'vue'
import {
    Radio,
    Stepper,
    Dialog,
    Button,
    Field,
    Icon,
    DropdownMenu,
    DropdownItem,
    Popup,
    NavBar,
    Cell,
    CellGroup,
    Loading,
    Overlay,
    RadioGroup,
    Toast,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Tabbar,
    TabbarItem,
    Calendar,
    List  
} from 'vant'

Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Button)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Dialog)
Vue.use(Stepper)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Toast)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Calendar);
Vue.use(List);