export default {
    'title': 'Privacy Policy ',
    'dataTime': 'Effective date:  15-01-2024',
    'data': {
        'descr': 'This Privacy Policy details the privacy practices of Visbody, Inc., Zhengqi Industrial Park, No. 555 Shanghua Road, Weiyang District (5-6/F, Zhengqi R&D Building), Xi\'an, Shanxi Province, China (“Visbody”, “we”, “us”, or “our”). This Privacy Policy applies solely to information collected through the websites located at the URLs: www.visbody.com. If you have questions about this Privacy Policy you can contact us at hello@visbody.com.'
    },
    'undefined': ' ',
    'title1-h': 'What information do we collect?',
    'title1': [
        '● Information you provide to us when you use the Platform, including your contact information, your login and password used to access the Platform, information about measurements taken with one of our scanners so we can give you insights or recommend custom solutions, information from services that you link to your Visbody account, and information about people you communicate with through the Platform.',
        '● Information about the computers and devices you use to access the Platform, including their location.',
        '● Information about your use of the Platform. We use cookies, beacons and other tracking technologies to collect this information.'
    ],
    'title2-h': 'How do we use your personal information?',
    'title2-h1h': 'Orders / Recommendations',
    'title2-h1': [
        '● To process your orders and fulfill your requests.',
        '● To recommend products and services based on your measurements.',
        '● To provide our products and the Platform to you.',
        '● To deliver your purchases.'
    ],
    'title2-h2h': 'Marketing',
    'title2-h2': [
        '● To send you emails, newsletters and other marketing communications.',
        '● To tell you about offers we think you will value.'
    ],
    'title2-h3h': 'Security',
    'title2-h3': [
        '● To protect the security of the Platform.',
        '● To enforce our terms and conditions.',
        '● To conduct market research and audience analysis, which allows us to understand who our customers are and the products/services they would like us to provide.',
        '● To provide certain information to our business clients.',
        '● To customize the content and advertising you see on the Platform and to recognize you as a previous visitor.'
    ],
    'title3-h': 'Why do we use your personal information?',
    'title3': [
        '● To allow us to satisfy our contractual obligations to you.',
        '● When you have given us your consent for these uses or otherwise in connection with our business.',
        '● We or our business clients have legitimate business interests in engaging in these activities and it may not be feasible to obtain your consent. We protect your rights by notifying you of this use, notifying you of your rights, and limiting the amount of information we use to only what is needed to fulfill these purposes.'
    ],
    'title4-h': 'Who do we share with your personal information?',
    'title4': [
        '● Other Visbody businesses:  We may share information with our affiliates where acceptable under applicable law.',
        '● Third Party service providers:  We instruct other companies and individuals to perform services on our behalf.  Examples include processing payments, sending e-mail, analyzing data, providing marketing assistance and responding to customer service requests.'
    ],
    'title5-h': 'How long do we keep your personal information?',
    'title5': [
        'We keep your information until you ask us to delete it.<a href=\'https://visbody.com/delete-my-information\' target=\'_blank\'>Submit your request here.</a> Once you ask us to delete your information, we will only keep your information as needed to fulfill our legal obligations or if it is in our legitimate interests to do so.'
    ],
    'title5-h1h': 'Your rights and choices',
    'title5-h1': [
        'We provide you with the ability to opt-out of promotional messages and interest-based advertising. If you are in the European Economic Area (EEA), you have the right to:',
        '● Access the information we have about you and to request that we correct or update it as needed.',
        '● Request a copy of the information we have about you.',
        '● Request that we delete the information we have about you.',
        '● to receive the data concerning them and provided by them and to transfer this data to other providers/controllers',
        '● to lodge a complaint with the supervisory authority if they believe that the data concerning them is being processed by the provider in breach of data protection provisions',
        '● Withdraw your consent to the processing of your information or request that we limit how we use your information.'
    ],
    'title5-h2h': 'Advertising',
    'title5-h2': [
        'You can choose whether or not to see advertising based on your interests.'
    ],
    'title6-h': 'Learn More about Visbody\'s Privacy Policy',
    'title6-h1h': 'What information do we collect?',
    'title6-h1': [
        'We collect information you voluntarily provide, as well as information that your computer, mobile phone, tablet, console or other device (collectively, “Device”) or browser provides automatically. We use cookies and other tracking technologies to make the Platform perform better for you and for advertising purposes. You can opt out of the use of cookies, but if you do, some features of the Platform will not work correctly.',
        'Information You Provide To Us:  When you use the Platform, we, or our service providers (acting on our behalf), collect “Personal Information” (which is information that, on its own or when combined with other information, can be used to identify you) that you provide to us, such as your first and last name, gender, email address, location, telephone number(s), mailing address, age, birth date, website link, location information, weight, biometric data and certain payment card information.',
        'We collect information from you when you:',
        '● Register to create an account;',
        '● Purchase items;',
        '● Use our products or services;',
        '● Participate on a blog;',
        '● Inquire about the Platform;',
        '● Receive our newsletter and/or advertisements;',
        '● Are referred to us by a friend or through a marketing promotion.',
        'Information about Others: You may decide to provide us with another person\'s email or phone number so that we can deliver information through the e-mail or another messaging service (such as WhatsApp). We use this information to contact and, if necessary, remind that person that he or she has been invited to join Visbody or received content through the Platform.',
        'Information Collected Automatically:  Whenever you visit or interact with the website, we, as well as our third-party advertisers and/or service providers, use a variety of technologies that automatically or passively collect information about how you access and use the website (“Usage Information”).  This statistical usage data provides us with information about the use of the website, such as how many visitors visit a specific page on the website, how long they stay on that page, and which hyperlinks, if any, they “click” on. This information helps us keep our website fresh and interesting to our visitors and tailor content to a visitor\'s interests.  Usage Information includes:',
        '● Your IP address or other unique identifier for your Device.',
        '● Device type.',
        '● The type of browser software and operating system you are using.',
        '● The web page(s) you have accessed, and the time you accessed them.',
        '● Product and Network usage Logs for error detection and support',
        'Cookies; Pixel Tags:  The technologies used on the Platform to collect Usage Information include but are not limited to: cookies (data files placed on a Device when it is used to visit the Platform), mobile analytics software and pixel tags (transparent graphic images, sometimes called web beacons or tracking beacons, placed on web pages or in emails, which indicate that a page or email has been viewed).  Cookies may also be used to associate you with social networking sites and, if you so choose, enable interaction between your activities on the Platform and your activities on such social networking sites.  We, or our vendors, may place cookies or similar files on your Device for security purposes, to facilitate site navigation and to personalize your experience while using our Platform (such as allowing us to select which content, ads or offers are most likely to appeal to you, based on your interests, preferences, location, or demographic information).  A pixel tag may tell your browser to get content from another server.',
        'To learn how to reduce the number of cookies you receive, or delete cookies that have already been installed in your browser’s cookie folder, please refer to your browser’s help menu or other instructions related to your browser. You can also learn more about cookies by visiting <a href=\'https://ico.org.uk/for-the-public/online/cookies\' target=\'_blank\'>https://ico.org.uk/for-the-public/online/cookies</a>, which includes additional useful information on cookies and how to block cookies using different types of browsers.',
        'Google Analytics:  We use a tool called “Google Analytics” to collect information about your internet use.  For example, how often you visit our site, the pages you visit, and what other Sites you may have visited prior to coming to our site.  We use Google Analytics to gain insights into how the website is used and to help us improve our products and services. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but Google.  To opt-out of Google Analytics, please use Google’s opt-out tool: <a href=\'https://tools.google.com/dlpage/gaoptout\' target=\'_blank\'>https://tools.google.com/dlpage/gaoptout.</a>',
        'Do Not Track:  Currently the website is not designed to respond to “Do Not Track” signals sent from your browser.'
    ],
    'title6-h2h': 'How do we use your personal information?',
    'title6-h2': [
        'We use the information we collect about you to provide you with products and services, to improve your experience, and to enforce our rights.',
        'We use the Personal Information we collect about and from you:',
        '● To perform our responsibilities under a contract we have with you. For example:',
        '● If you purchase goods or services using the Platform, we will need to use your Personal Information to process your order and to enable delivery;',
        '● We will use your body measurements for the purposes for which you were scanned (for example, to provide insights about your health or recommend particular products to you);',
        '● We will need to use your Personal Information to perform our dashboard platform or website terms of use.',
        '● When we or our business clients have a legitimate interest in such use. For example:',
        '● To prevent fraud and protect the security of the Platform;',
        '● To enforce our terms of use;',
        '● To communicate updates and improvements;',
        '● To carry out market research and Platform analytics;',
        '● To develop and optimize our products and services;',
        '● To send you marketing communications about products and services we offer that are similar to products and services you have purchased from us;',
        '● To help us provide and operate the Platform;',
        '● To provide our business clients with analytics, artificial intelligence, recommendations, audience segments regarding consumer’s use of the Platform or our products and services;',
        '● To comply with our legal obligations.',
        '● With your consent. For example:',
        '● We send certain marketing communications to you only if you consent to receive them.',
        '● We do not share your Personal Information with third parties for their direct marketing purposes without your consent.',
        '● With your consent, we use your information to personalize your Visbody experience, including: to tailor content, advertisements, and offers we serve you.'
    ],
    'title6-h3h': 'Who Do We Share Your Information With?',
    'title6-h3': [
        'We share your information with our distributor and service providers. We will share Personal Information as necessary to enforce our legal rights or meet our legal obligations.',
        'We may share your Personal Information with third parties in the following circumstances:',
        '● Distributor:  Other Visbody businesses (our “Distributor”) jointly use the Personal Information for the purposes described in this Privacy Policy, where this is acceptable under applicable law. .',
        '● Third Party Service Providers:  We use third parties to help us provide the Platform to you.  Third parties we use for Platform hosting, data analytics, email, marketing, customer service and other services will access your Personal Information to help us provide these services to you.  We will provide your Personal Information to these third-party service providers when the information is necessary for them to perform their duties.',
        '● When you Agree. While on our Platform, you may have the opportunity to opt-in to receive information and/or marketing offers from someone else or to otherwise consent to the sharing of your information with a third party. If you use the social sharing features of the Platform, your information may be shared with certain of your friends or groups. If you agree to have your Personal Information shared, your Personal Information will be disclosed to the third party and the Personal Information you disclose will be subject to the privacy policy and business practices of that third party.',
        '● With Third Parties, Where Necessary for Legal Reasons:  We will transfer and disclose your Personal Information to third parties where we need to do so in order to conform to the requirements of the law or comply with legal process served upon us, to make sure you are complying with our terms of use or other applicable policies, to investigate possible fraud, or to help investigate or fix any security or technical issues relating to the Platform.',
        ' '
    ],
    'title6-h4h': 'Advertising',
    'title6-h4': [
        'You can choose whether or not to see advertising based on your interests. If you opt-out, you will still see ads, but they will be less relevant to you.',
        'Visbody may use information derived from your use of the Platform or our products and services to determine what content we believe may be of interest to you, including content from the providers of goods and services that you may be able to purchase through the Platform or otherwise (who may pay us to show you such content). Note, however, that if a third-party content provider asks us to show content to users with certain characteristics (for example, women ages 18-24) or a segment of that group (for example, women ages 18-24 who have indicated they are interested in triathlons and you respond to that content or click on links embedded in that content, the third-party content provider may conclude that you have the characteristics of the audience that they are trying to reach. Visbody does not have access to or control over cookies, web beacons, or other technologies that third parties who have asked us to show you content may use, and the privacy practices of these third parties are not covered by this privacy policy. Please contact these companies directly for information on their data collection and privacy practices. For more information on available opt-out options for targeted advertising delivered by NAI member ad networks, see http://www.networkadvertising.org/.',
        'We may use third party ad network providers to help present ads on the Platform, as well as other service providers to evaluate and provide us with information about the use of the Platform and viewing of our content. Such providers may place and access cookies, pixel tags, or similar technologies on your Device to serve you ads or other content personalized to your interests which they infer from your browsing on the Platform and other sites you have visited. In doing so, the provider collects or has access to non-Personal Information such as your Usage Information that does not identify you. The use of cookies, pixel tags, or similar technologies by these providers is subject to their own privacy policies, not ours.',
        'If you do not want to receive the benefits of targeted advertising, you may opt out of some network advertising programs that use your information by visiting the NAI Opt-Out Page at http://www.networkadvertising.org/managing/opt_out.asp or the Digital Advertising Alliance consumer choice page at www.aboutads.info/choices.',
        'Please note that even if you choose to remove your information (opt out), you will still see advertisements while you’re browsing online. However, the advertisements you see may be less relevant to you and your interests. Additionally, many network advertising programs allow you to view and manage the interest categories they have compiled from your online browsing activities. These interest categories help determine the types of targeted advertisements you may receive. The NAI Opt-Out Page and the DAA consumer choice page provide mechanisms to opt out of receiving cookies from those companies. Please note that if you opt-out of targeted advertising, we may still track your visits to the Platform for our own analytics, operations and security purposes.'
    ],
    'title6-h5h': 'Children',
    'title6-h5': [
        'We do not knowingly collect personal information from children.'
    ],
    'title6-h6h': 'Storage of your personal information',
    'title6-h6': [
        'We keep your information until you ask to delete it. Once you ask to delete your information, we will only keep your information as needed to fulfil our legal obligations, resolve disputes, enforce our agreements, or take other actions permitted by law.',
        'We will generally store your information until you ask us to delete it, or until your account is deleted, whichever comes first; but there are some exceptions to this general rule. We will retain information from deleted accounts to comply with the law, prevent fraud, collect fees, resolve disputes, troubleshoot problems, assist with investigations, process warranty claims, distribute important product information (such as recall information), enforce our agreements, and take other actions permitted by law.',
        'You can request deletion of your Personal Information at any time by contacting our Data Protection Officer using the contact details set out below.'
    ],
    'title6-h7h': 'Security Measures',
    'title6-h7': [
        'We use technical and organizational security measures to protect your information, but we cannot guarantee they will be 100% effective.',
        'We use a variety of current technologies and processes and maintain physical, technical and administrative safeguards for the protection of our customer data. We maintain a private database on our servers for the storage of all information collected through the Platform. It is our practice to use encryption whenever we receive or transmit sensitive data. We use encryption technology to ensure the secure transmission of any personal information you provide while using the Platform. We will take reasonable measures which we believe are appropriate to protect your information from loss, misuse, alteration or destruction, and, where possible, will ask that any third parties to whom we may transfer your information take comparable steps to protect that security. Although we will use all reasonable efforts to safeguard the confidentiality of your Personal Information, we cannot guarantee that your information will always be secure.',
        'Email is not a secure form of communication. Please do not send us your credit card number, social security number or other Personal Information via email.'
    ],
    'title6-h8h': 'Your rights and choices',
    'title6-h8': [
        'We provide you with the ability to opt-out of promotional messages and interest-based advertising. If you are in the EEA, you have the right to access the information we have about you, as well as the right to request that we correct it, complete it, erase it, stop using it, or transfer it to you. You can stop receiving promotional communications from us by following the opt-out instructions provided in any such communication you receive. You can also email hello@visbody.com.',
        'See “Advertising” above to learn how to view and opt-out of certain interest-based advertising.',
        'If you are in the EU, you have certain rights related to the Personal Information we hold about you:',
        '● Access.  You have the right to access the Personal Information we hold about you.  If you wish to obtain a copy or description of the Personal Information we hold about you,you may send your request to our Data Protection Officer (e.g., email or by post) using the contact details set out below.  In either case, we may ask you to verify your identity and to provide further details about your request.',
        '● Accuracy. We will do our best to ensure the Personal Information we retain about you is accurate.  We may from time to time send you an email update to remind you to tell us about any updates or changes to your Personal Information.  You have the right to request that any inaccurate Personal Information is corrected and any incomplete information is completed by contacting our Data Protection Officer using the contact details set out below.',
        '● Deletion and Processing Restriction Requests.  You have the right to request that we delete Personal Information that we hold about you.  You also have the right to ask us to stop processing your Personal Information, subject to certain exceptions.  If you would like us to erase or stop processing the Personal Information that we hold about you, please contact our Data Protection Officer using the contact details set out below.',
        '● Portability Requests.  You have the right to request that we provide certain parts of your Personal Information to you or transmit it directly to another company that processes Personal Information.  If you would like us to transfer your Personal Information, please contact our Data Protection Officer using the contact details set out below.',
        '● Withdrawing Your Consent to Receive Marketing Messages.  You may ask us to stop using your Personal Information for advertising or marketing purposes at any time.  If you wish to do this, please follow the removal instructions in any communication you receive, or send us an email to hello@visbody.com with Unsubscribe in the subject line.'
    ],
    'title7h': 'Changes to our Privacy Policy',
    'title7': [
        'We may change our privacy practices, and we will update this page when we do, so be sure to check it periodically.',
        'We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes we will provide you with a prominent notification prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.'
    ],
    'title8h': 'Queries or Complaints',
    'title8': [
        'If you have any questions or concerns about this Privacy Policy, the Platform, or about our use of your Personal Information, please contact our Data Protection Officer using the contact details set out below. In addition, if you have any complaints about how we use your Personal Information, you have the right to lodge a complaint with the data protection authority in your country. A list of data protection authorities is available at http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.'
    ],
    'title9h': 'Our Contact Details',
    'title9': [
        'You may contact us using the following details:',
        'By email: hello@visbody.com',
        'Address: Zhengqi Industrial Park, No. 555 Shanghua Road, Weiyang District (5-6/F, Zhengqi R&D Building), Xi\'an, Shaanxi Province, China'
    ]
}
