import ptLocale from 'element-ui/lib/locale/lang/pt'
import agrEn from './agr-en'
export default {
    'login': {
      'userLogin': 'Acesso',
      'title': 'A conta configurada por e-mail e número de telefone são independentes uma da outra, por favor.',
      'mobileLogin': 'Telefone',
      'emailLogin': 'Email',
      'email': 'E-mail',
      'loginBtn': 'Entrar',
      'autoLogin': 'Lembrar-me',
      'retransmission': 's',
      'formList': [
        {
          'label': 'Insira seu número de telefone',
          'placeholder': 'Enviar'
        },
        {
          'label': 'Código de verificação',
          'placeholder': 'Insira o código de verificação'
        },
        {
          'label': 'Por favor, insira seu e-mail'
        }
      ],
      'btn': {
        'submit': 'Criar conta',
        'title': 'Para obter dados precisos, é importante inserir a altura, gênero e idade corretos'
      },
      'rule': {
        'phone': {
          'requiredMsg': 'Formato de número de telefone inválido',
          'patternMsg': 'Telefone não registrado, crie sua conta'
        },
        'authCode': {
          'requiredMsg': 'Código de verificação incorreto, por favor digite novamente'
        },
        'email': {
          'requiredMsg': 'Formato de e-mail incorreto',
          'patternMsg': 'E-mail não registrado, crie sua conta'
        }
      },
      'success': 'Envio realizado com sucesso',
      'error': 'Falha no envio',
      'loginSuccess': 'Login realizado com sucesso',
      'loginAndRegister': 'Cadastre-se / Entrar',
      'codeSuccess': 'O código de verificação foi enviado, por favor, verifique.',
      'registerAndLogin': 'Entrar'
    },
    'register': {
      'mobileRegister': 'Telefone',
      'emailRegister': 'Email',
      'enroll': 'Número de celular já registrado',
      'search': 'Pesquisar',
      'title': 'A conta configurada por e-mail e número de telefone são independentes uma da outra, por favor.',
      'needRead': {
        'agree': 'Eu concordo',
        'privacy': '(Política de Privacidade)'
      },
      'register': 'Entrar',
      'check': 'Verificar \'Privacidade do Usuário\'',
      'newUser': 'Entrar',
      'form': {
        'labelList': [
          'Nome',
          '* Gênero',
          '* Altura',
          '* Idade'
        ]
      },
      'placeholder': [
        'Por favor insira seu nome',
        'Por favor insira sua altura',
        'Por favor insira sua idade'
      ],
      'unit': [
        'Medição',
        'Imperial',
        ''
      ],
      'sexOptions': {
        'male': 'Masculino',
        'female': 'Feminino'
      },
      'btn': 'Enviar',
      'rules': {
        'metricheight': [
          'A faixa de altura é de 2\'3\'\' a 6\'7\'\'. Por favor, insira novamente'
        ],
        'heightMsg': [
          'A faixa de altura é de 70 a 200cm. Por favor, insira novamente'
        ],
        'ageMsg': [
          'A faixa etária é de 10 a 99 anos, por favor, insira novamente'
        ],
        'agePointMsg': [
          'Preenchimento fracionado: a idade só pode ser um número inteiro, por favor, preencha a idade corretamente'
        ]
      },
      'success': 'Registro realizado com sucesso',
      'userMessage': 'Insira o nome do usuário',
      'verify': 'Insira o código de verificação de 4 dígitos',
      'errorMsg': 'Formato de altura não suporta decimais. Por favor, insira novamente',
      'enrollEmail': 'Email registrado',
      'abnormalMsg': 'Anomalia de registro'
    },
    'model': {
      'loading': 'Carregando... Por favor, aguarde um momento',
      'modelLoading': 'Carregando Modelo 3D',
      'tabPane': [
        {
          'title': 'Tecnologia Deep Real Sense',
          'msg': 'Colete com precisão detalhes locais do corpo através do escaneamento 3D, garanta a precisão de milímetros na medição da circunferência e apresente claramente as mudanças do formato do corpo após o treino'
        },
        {
          'title': 'Algoritmo de composição corporal BDA',
          'msg': 'O Visbody usa o sistema de medição de composição corporal mais avançado (algoritmo BDA) para determinar a composição corporal. Este método de cálculo, baseado no volume corporal, avaliará com mais precisão os riscos à saúde trazidos pela obesidade.'
        },
        {
          'title': 'Fatores que causaram erros',
          'msg': 'Roupas largas ou algumas decorações no corpo causarão erros, por favor, use roupas justas ou reduza a quantidade de roupas no seu corpo, se quiser obter dados mais precisos.'
        },
        {
          'title': 'Obtenha seu relatório',
          'msg': 'Recomenda-se salvar a URL da página da web para facilitar a visualização do relatório no futuro. Você também pode enviar este relatório para sua caixa de correio para salvá-lo mais tarde'
        }
      ],
      'collect': [
        'Por favor, salve esta página, Fácil de revisar o relatório no futuro. Fácil de revisar o relatório no futuro.',
        ''
      ],
      'know': 'Eu entendi',
      'reminder': [
        'Lembrar-me',
        'Seu modelo de avatar 3D foi gerado, deslize para a esquerda e para a direita para girá-lo',
        'Seu relatório foi gerado e pode ser visualizado clicando no botão \'Ver Relatório\'',
        'Sua avaliação corporal não foi bem sucedida. Por favor, revise o relatório de composição corporal.',
        'Sua composição corporal não foi bem sucedida. Por favor, revise o relatório de avaliação postural.',
        'Sua medição não foi bem sucedida. Por favor, volte ao dispositivo e tente novamente.',
        'A medição da composição corporal não foi bem sucedida. Por favor, volte ao dispositivo e tente novamente.',
        'A avaliação postural não foi bem sucedida. Por favor, volte ao dispositivo e tente novamente.'
      ],
      'btn': {
        'viewReport': 'Ver relatório',
        'bodyReport': 'Ver relatório de composição corporal',
        'postureReport': 'Ver relatório de postura corporal',
        'lastReport': 'Ver último relatório',
        'loading': 'Carregando'
      }
    },
    'report': {
      'btn': {
        'download': 'Baixar o relatório',
        'send': 'Enviar relatório',
        'report': 'Relatório'
      },
      'reportSuccess': 'Sucesso!',
      'tabPane': {
        'labelList': [
          'Tempo de medição',
          'Item',
          'Composição corporal',
          'Avaliação de postura',
          'Função do ombro'
        ]
      },
      'sendEmail': 'Email:',
      'requiredMsg': 'Digite seu endereço de e-mail',
      'patternMsg': 'Por favor, insira um endereço de e-mail válido',
      'send': 'Enviar',
      'download': 'Baixar relatório',
      'sendReport': 'Enviar relatório'
    },
    'sidebar': {
      'successMsg': {
        'sex': 'Gênero alterado com sucesso',
        'height': 'Altura alterada com sucesso',
        'age': 'Idade alterada com sucesso'
      },
      'logOut': 'Sair',
      'personal': 'Centro Pessoal',
      'cut': 'Alterar Unidades/Idiomas',
      'unit': [
        'Métricas (kg, cm)',
        'Imperial (ft, in, lb)'
      ],
      'read': {
        'reading': 'Leia',
        'privacy': '(Política de Privacidade)'
      },
      'setting': 'Configurações',
      'form': {
        'labelList': [
          'Telefone',
          'Unidades',
          'Idiomas',
          'Nome',
          'Gênero',
          'Altura',
          'Idade'
        ]
      },
      'placeholder': [
        'Altere seu nome de usuário',
        'Novo gênero',
        'Altura atualizada',
        'Idade atualizada'
      ],
      'btn': {
        'cancelBtn': 'Cancelar',
        'accomplishBtn': 'Completar',
        'confirmBtn': 'Confirmar'
      },
      'dialog': [
        'O gênero é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar isso.',
        'A altura é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar isso.',
        'A idade é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar isso.'
      ],
      'rules': {
        'metricheight': [
          'Por favor, preencha a faixa de altura correta (2\' 3\'\' a 6\'7\'\')'
        ],
        'heightMsg': [
          'Por favor, preencha a altura dentro de 70cm a 200cm'
        ],
        'ageMsg': [
          'Por favor, preencha a idade dentro de 10 a 99 anos',
          'Preenchimento fracionado, a idade só pode ser um número inteiro. Por favor, preencha a idade correta.'
        ]
      },
      'heightMsg': 'Por favor, preencha novamente a altura',
      'ageMsg': 'Por favor, preencha novamente a idade',
      'ModelBinding': 'Parece que alguém já escaneou o código. Por favor, meça novamente!',
      'ReportEmpty': {
        'title': 'Você ainda não tem nenhum relatório',
        'desc': 'Vá para o scanner corporal 3D Visbody e teste novamente!'
      },
      'changeSort': 'Troca de relatório do produto',
      'describe': 'Após a troca do modelo do produto, todos os relatórios de medição sob este modelo serão exibidos.',
      'describeBtn': 'Após a troca do modelo do produto, você será redirecionado para o relatório de medição mais recente sob este modelo.',
      'descReport': 'Troca de relatório do produto realizada com sucesso'
    },
    'modelBinding': {
      'desc': 'Parece que alguém já escaneou o código. Por favor, meça novamente!'
    },
    'reportEmpty': {
      'title': 'Você ainda não tem nenhum relatório',
      'desc': 'Vá para o scanner corporal 3D Visbody e teste novamente!',
      'descS30': 'Vá para o scanner corporal 3D Visbody e teste novamente!',
      'descM30': 'Vá para o scanner corporal 3D Visbody e teste novamente!'
    },
    'userInfo': {
      'height': 'Altura:',
      'age': 'Idade:',
      'unit': '',
      'weight': 'Peso'
    },
    'null': 'null',
    'mass': {
      'title': 'Visão geral da avaliação de composição corporal',
      'titles': 'Composição corporal',
      'springFrame': 'Sem dados de medição para o dia',
      'contrast': 'Escolha um relatório para comparar',
      'contrastReport': 'Escolha um relatório para comparar',
      'noRecord': 'Sem registros',
      'score': 'Pontuação',
      'status': 'Seu atual estado de composição corporal',
      'WT': 'Peso',
      'PBF': 'Percentual de gordura corporal',
      'measure': {
        'value': 'Valores',
        'standardRange': 'Faixa padrão',
        'comparedWithLastLime': 'Líquido',
        'comparedNet': 'Comparado com a última pontuação',
        'WT': 'Peso',
        'FFM': 'Massa Livre de Gordura',
        'BFM': 'Massa Gordura',
        'LM': 'Massa Muscular',
        'TBW': 'Água Total',
        'SM': 'SMM (Massa Muscular Esquelética)',
        'PROTEIN': 'Proteína',
        'TM': 'Minerais',
        'BMR': 'Basal Metabolic Rate',
        'WHR': 'Relação Cintura-Quadril',
        'BMI': 'IMC',
        'PBF': 'PGC (Percentual de Gordura Corporal)',
        'VFG': 'Nível de Gordura Visceral',
        'unit': 'Kg',
        'BMRUnit': 'Kcal/d',
        'current': 'Pontuação desta medição'
      },
      'explain': [
        {
          'title': 'Peso',
          'msg': 'O peso é a soma da água corporal, proteína, sal inorgânico e peso corporal.'
        },
        {
          'title': 'Massa Livre de Gordura',
          'msg': 'A massa corporal magra é o peso corporal total sem gordura.'
        },
        {
          'title': 'Massa Gordura',
          'msg': 'A massa de gordura corporal é a soma da gordura subcutânea, gordura visceral e gordura muscular.'
        },
        {
          'title': 'Massa Muscular',
          'msg': 'A massa magra suave é a massa corporal magra, que inclui músculo esquelético, músculo liso e músculo cardíaco.'
        },
        {
          'title': 'Água Total',
          'msg': 'A maior parte do corpo humano é composta por água, com uma quantidade de 50% a 70% do peso corporal. E a água corporal está principalmente nas células humanas e nos fluidos corporais, a maior parte dos quais está nas células musculares.'
        },
        {
          'title': 'MME',
          'msg': 'A massa muscular esquelética, também conhecida como músculo estriado, é um tipo de músculo ligado aos ossos. Esses dados contêm a quantidade de Músculo Esquelético.'
        },
        {
          'title': 'Proteína',
          'msg': 'A proteína é uma substância sólida com amônia, que existe em todas as células do corpo humano. É o principal componente da massa muscular.'
        },
        {
          'title': 'Minerais',
          'msg': 'O corpo humano é composto por matéria orgânica, matéria inorgânica e água. A matéria inorgânica aqui são sais inorgânicos, que correspondem a 5% do peso corporal.'
        },
        {
          'title': 'Basal Metabolic Rate',
          'msg': 'A taxa de metabolismo basal é a energia total consumida em um dia quando o corpo está em repouso, não afetada por exercícios, objetos físicos, nervosismo, mudanças de temperatura externa, etc.'
        },
        {
          'title': 'Relação Cintura-Quadril',
          'msg': 'A relação da circunferência da cintura com o quadril é um indicador importante para determinar a obesidade central.'
        },
        {
          'title': 'IMC',
          'msg': 'O IMC é usado, principalmente, para avaliar o grau da obesidade e é um padrão comum para medir a gordura corporal.'
        },
        {
          'title': 'PGC',
          'msg': 'PGC é uma medida da composição corporal que indica quanto do peso corporal é gordura.'
        },
        {
          'title': 'Nível de Gordura Visceral',
          'msg': 'A gordura visceral é um indicador importante para avaliar a obesidade oculta.'
        },
        {
          'title': 'Água Intracelular',
          'msg': 'Água Intracelular: é o fluido corporal intracelular e é o componente básico do protoplasma.'
        },
        {
          'title': 'Água extra-celular',
          'msg': 'Água extra-celular: normalmente refere-se aos fluidos corporais extracelulares, incluindo plasma e fluido intersticial entre vasos sanguíneos e células teciduais.'
        },
        {
            title: 'Idade Metabólica',
            msg: 'A idade metabólica refere-se ao desempenho do corpo e suas funções de saúde relacionadas ao metabolismo.',
        },
      ],
      'segment': {
        'fat': 'Análise segmentar de gordura',
        'muscle': 'Análise segmentar da massa magra',
        'right': 'Direito',
        'left': 'Esquerdo',
        'standard': 'Normal',
        'lowStandard': 'Baixo',
        'superStandard': 'Alto',
        'peel': '(Tare)',
      },
      'massFrame': 'Sem dados de medição para o dia',
      'endSymbol': '.'
    },
    'shape': {
      'title': 'Visão geral da avaliação corporal',
      'titles': 'Postura',
      'status': 'Sua condição física atual',
      'suggest': 'Sugestão',
      'possibility': ', existe a possibilidade de',
      'models': {
        'front': 'Frente',
        'left': 'Lado esquerdo',
        'right': 'Lado direito',
        'top': 'Atrás',
        'low': 'Baixo',
        'high': 'Alto',
        'normal': 'Normal'
      },
      'item': {
        'deviate': 'Valor da medição',
        'result': 'Explicação',
        'normal': 'Normal',
        'abnormal': 'Anormal',
        'head': 'Postura de cabeça para frente',
        'headSlant': 'Inclinação da cabeça',
        'roundShoulderLeft': 'Postura protusa/curvada de ombros (lado esquerdo)',
        'roundShoulderRight': 'Postura protusa/curvada de ombros (lado direito)',
        'highLowShoudler': 'Ombros desiguais',
        'leftKneeCheck': 'Avaliação do joelho esquerdo',
        'rightKneeCheck': 'Avaliação do joelho direito',
        'leg': 'Forma da perna',
        'leftLeg': 'Perna esquerda',
        'rightLeg': 'Perna direita',
        'pelvis': 'Deslocamento pélvico para frente/Deslocamento pélvico posterior'
      },
      'explain': [
        {
          'title': 'Postura de cabeça para a frente',
          'msg': 'O ângulo entre o ponto da orelha do lado esquerdo, a linha que conecta o centro do pescoço e a linha mediana do lado'
        },
        {
          'title': 'Inclinação da cabeça',
          'msg': 'O ângulo entre o ponto médio da frente da cabeça, o centro do pescoço e a linha média da frente'
        },
        {
          'title': 'Postura de ombros curvados (lado esquerdo)',
          'msg': 'O ângulo entre a linha que conecta o ponto mais alto do lado esquerdo das costas e a linha tangente do ombro'
        },
        {
          'title': 'Postura de ombros curvados (lado direito)',
          'msg': 'O ângulo entre a linha que conecta o ponto mais alto do lado direito das costas e a tangente ao ombro'
        },
        {
          'title': 'Ombros desiguais',
          'msg': 'A distância vertical entre os pontos do ombro esquerdo e direito nas costas'
        },
        {
          'title': 'Deslocamento pélvico para frente/deslocamento pélvico posterior',
          'msg': 'O ângulo incluído da linha que conecta o ponto central do pescoço, o ponto do osso do quadril e o ponto do tornozelo do lado esquerdo'
        },
        {
          'title': 'Avaliação do joelho esquerdo',
          'msg': 'O ângulo incluído da linha de três pontos que conecta o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo do lado esquerdo'
        },
        {
          'title': 'Avaliação do joelho direito',
          'msg': 'O ângulo incluído da linha de três pontos que conecta o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo do lado direito'
        },
        {
          'title': 'Forma da perna',
          'msg': 'O ângulo incluído da linha que conecta o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo na frente das pernas esquerda e direita'
        }
      ],
      'exception': [
        {
          'title': 'Inclinado para o lado esquerdo',
          'abnormal': 'Inclinado para o lado esquerdo'
        },
        {
          'title': 'Inclinado para o lado direito',
          'abnormal': 'Inclinado para o lado direito'
        },
        {
          'title': 'Esquerda alta',
          'abnormal': 'Esquerda alta'
        },
        {
          'title': 'Direita alta',
          'abnormal': 'Direita alta'
        },
        {
          'title': 'Inclinação pélvica posterior',
          'abnormal': 'Inclinação pélvica posterior'
        },
        {
          'title': 'Inclinação pélvica anterior',
          'abnormal': 'Inclinação pélvica anterior'
        },
        {
          'title': 'Hiperextensão do joelho esquerdo',
          'abnormal': 'Hiperextensão do joelho esquerdo'
        },
        {
          'title': 'Flexão anterior do joelho esquerdo',
          'abnormal': 'Flexão anterior do joelho esquerdo'
        },
        {
          'title': 'Hiperextensão do joelho direito',
          'abnormal': 'Hiperextensão do joelho direito'
        },
        {
          'title': 'Flexão anterior do joelho direito',
          'abnormal': 'Flexão anterior do joelho direito'
        },
        {
          'title': 'Pernas em forma de K',
          'abnormal': 'Pernas em forma de K'
        },
        {
          'title': 'Pernas em forma de D',
          'abnormal': 'Pernas em forma de D'
        },
        {
          'title': 'Pernas em forma de X',
          'abnormal': 'Pernas em forma de X'
        },
        {
          'title': 'Pernas em forma de O',
          'abnormal': 'Pernas em forma de O'
        }
      ]
    },
    'girth': {
      'title': 'Circunferências',
      'present': '(Presente)',
      'tpl': [
        {
          'title': 'Circunferência do pescoço',
          'key': 'neckGirth'
        },
        {
          'title': 'Braço superior esquerdo',
          'key': 'leftUpperArmGirth'
        },
        {
          'title': 'Braço superior direito',
          'key': 'rightUpperArmGirth'
        },
        {
          'title': 'Peito',
          'key': 'bustGirth'
        },
        {
          'title': 'Cintura alta',
          'key': 'waistGirth'
        },
        {
          'title': 'Cintura média',
          'key': 'midWaistGirth'
        },
        {
          'title': 'Quadril',
          'key': 'hipGirth'
        },
        {
          'title': 'Coxa esquerda',
          'key': 'leftThighGirth'
        },
        {
          'title': 'Meio da coxa esquerda',
          'key': 'leftMidThighGirth'
        },
        {
          'title': 'Circunferência mínima da coxa esquerda',
          'key': 'leftMinThighGirth'
        },
        {
          'title': 'Circunferência da coxa direita',
          'key': 'rightThighGirth'
        },
        {
          'title': 'Meio da coxa direita',
          'key': 'rightMidThighGirth'
        },
        {
          'title': 'Circunferência mínima da coxa direita',
          'key': 'rightMinThighGirth'
        },
        {
          'title': 'Circunferência da panturrilha esquerda',
          'key': 'leftCalfGirth'
        },
        {
          'title': 'Circunferência da panturrilha direita',
          'key': 'rightCalfGirth'
        },
        {
          'title': 'Cintura baixa',
          'key': 'lowWaistGirth'
        }
      ],
      'noHistory': 'Sem dados'
    },
    'shoulder': {
      'title': 'Função do ombro',
      'model': {
        'val': 'Valor',
        'scope': 'Local de Atividade Máxima',
        'diff': 'Líquido'
      },
      'normal': 'Bom',
      'conclusion': 'Conclusão',
      'analyse': 'Análise:',
      'suggest': 'Sugestão:',
      'cause': 'Por favor, peça informações aos especialistas para mais detalhes.',
      'normalAll': 'A função do ombro não apresenta anomalias evidentes no momento.'
    },
    'saveQr': {
      'title': 'Relatório de escaneamento corporal 3D Visbody',
      'btnMsg': 'Mantenha pressionada a imagem para salvá-la em seu telefone.',
      'tips': 'Se você não conseguir salvar, por favor, vá para [Configurações] e abra as permissões correspondentes.'
    },
    'reportTips': {
      'title': 'Por favor, entre em contato com o administrador para deletar o relatório.',
      'btnMsg': 'OK',
      'alaryTips': 'Este relatório foi deletado pelo administrador. Irá pular para o próximo relatório!'
    },
    'share': {
      'title': 'Por favor, copie o link abaixo e compartilhe.',
      'btnMsg': 'Copiar',
    },
    ...ptLocale,
    ...agrEn
  }
  