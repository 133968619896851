/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:59:42
 * @LastEditors: liutq
 * @LastEditTime: 2024-05-10 15:33:48
 */
// 读取环境变量
// const ENV = process.env.RUNTIME_ENV
// 后端服务地址
export const postUrl = process.env.VUE_APP_API_HOST

export const apiUrl = postUrl + '/graphql'

export const reportHost = process.env.VUE_APP_PRINT_API_HOST 

export const shareTime = process.env.VUE_APP_SHARE_TIME

// 百度统计配置
export const baiduOptions = {
    siteId: process.env.HMT_ID
}

// 上传静态文件路径
export const upyunUrl = process.env.UPLOAD_URL

// 维塑公众号id
export const appId = 'wxad26ae1d5bd54e89'

// export const qrcodeImg =
//     ['development', 'dev', 'test'].indexOf(ENV) > -1
//         ? require('@/assets/images/qrcode_for_gh_322a8987a12c_344.jpg')
//         : require('@/assets/images/qrcode.jpg')
