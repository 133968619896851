
import idLocale from 'element-ui/lib/locale/lang/id.js'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Daftar/Masuk',
        registerAndLogin: 'Masuk/Daftar',
        userLogin: 'Masuk',
        title: 'Akun telepon dan email adalah independen; mohon perhatikan tipe akun Anda.',
        mobileLogin: 'Telepon',
        emailLogin: 'Email',
        email: 'E-mail',
        loginBtn: 'Masuk',
        autoLogin: 'Ingat saya',
        retransmission: 'detik',
        formList: [
            {
                label: 'Masukkan nomor telepon Anda',
                placeholder: 'Kirim ',
            },
            {
                label: 'Kode Verifikasi',
                placeholder: 'Masukkan kode verifikasi',
            },
            {
                label: 'Silakan masukkan email Anda',
            },
        ],
        btn: {
            submit: 'Buat Akun',
            title: 'Informasi yang akurat sangat penting, silakan isi dengan hati-hati.',
        },
        rule: {
            phone: {
                requiredMsg: 'Format Nomor Telepon Tidak Valid',
                patternMsg: 'Nomor telepon belum terdaftar,silakan buat akun baru.',
            },
            authCode: {
                requiredMsg: 'Kode verifikasi salah, silakan masukkan ulang.',
            },
            email: {
                requiredMsg: 'Format email tidak valid.',
                patternMsg: 'Email Tidak Terdaftar, Buat Akun Anda',
            },
        },
        codeSuccess: 'Kode verifikasi telah dikirim, silakan periksa.',
        success: 'Berhasil dikirim.',
        error: 'Pengiriman gagal.',
        loginSuccess: 'Berhasil masuk.',
    },
    // 注册页面
    register: {
        mobileRegister: 'Telepon',
        emailRegister: 'Email',
        enroll: 'Nomor Ponsel Sudah Terdaftar',
        enrollEmail: 'Email sudah terdaftar',
        search: 'Cari',
        title: 'Akun yang diatur dengan email dan nomor telepon adalah  independen satu sama lain, silahkan',
        needRead: {
            agree: 'Saya setuju',
            privacy: '(Kebijakan Privasi)',
        },
        register: 'Daftar',
        check: 'Periksa "Privasi Pengguna"',
        newUser: 'Masuk',
        form: {
            labelList: ['Nama', '* Jenis kelamin', '* Tinggi badan', '* Usia'],
        },
        placeholder: ['Silakan masukkan nama Anda', 'Silakan masukkan tinggi Anda', 'Silakan masukkan usia Anda'],
        unit: ['Metrik', 'Imperial', ''],
        sexOptions: {
            male: 'Pria',
            female: 'Wanita',
        },
        btn: 'Kirim',
        rules: {
            metricheight: [`Rentang tinggi adalah 2'3" -  6'7", silakan masukkan lagi.`],
            heightMsg: ['Rentang tinggi adalah 70 - 200cm, silakan masukkan  lagi.'],
            ageMsg: ['Rentang usia adalah 10 - 99, silakan masukkan lagi.'],
            agePointMsg: ['Diisi bukan bilangan bulat: usia hanya bisa bilangan  bulat, silakan isi usia yang benar'],
        },
        success: 'Pendaftaran berhasil',
        userMessage: 'Masukkan Nama Pengguna',
        verify: 'Masukkan Kode Verifikasi 4 Digit',
        errorMsg: 'Format tinggi tidak mendukung desimal, silakan masukkan lagi.',
        abnormalMsg: 'Anomali pendaftaran'
    },
    // 模型合成
    model: {
        loading: 'Memuat...Silakan tunggu sebentar',
        modelLoading: 'Memuat Model 3D...',
        tabPane: [
            {
                title: 'Teknologi Deep Real Sense',
                msg: 'Mengumpulkan detail lokal tubuh secara akurat melalui  pemindaian 3D, memastikan akurasi pengukuran lingkar tingkat milimeter, dan  dengan jelas menampilkan perubahan bentuk tubuh setelah olahraga.',
            },
            {
                title: 'Algoritma Komposisi Tubuh BDA',
                msg: 'Visbody menggunakan sistem pengukuran komposisi tubuh  paling canggih - algoritma BDA untuk menentukan komposisi tubuh. Metode  perhitungan ini berdasarkan volume tubuh akan lebih akurat menilai risiko  kesehatan yang dibawa oleh obesitas.',
            },
            {
                title: 'Faktor yang menyebabkan kesalahan',
                msg: 'Pakaian longgar atau beberapa dekorasi pada/di dalam  tubuh akan menyebabkan kesalahan, silakan kenakan pakaian ketat atau kurangi  jumlah pakaian di tubuh Anda jika Anda ingin mendapatkan data yang paling  akurat.',
            },
            {
                title: 'Dapatkan laporan Anda',
                msg: 'Dianjurkan untuk menandai URL halaman web untuk  memudahkan melihat laporan di masa mendatang. Anda juga dapat mengirim  laporan ini ke kotak surat Anda untuk menyimpannya nanti.',
            },
        ],
        collect: ['Harap tandai halaman ini untuk memudahkan akses laporan di masa mendatang.', ''],
        know: 'Saya Mengerti',
        reminder: [
            'Tips Hangat',
            'Model avatar 3D Anda telah dibuat, geser ke kiri dan  kanan untuk memutar model.',
            'Laporan Anda telah dibuat dan dapat dilihat dengan  mengklik tombol "Lihat Laporan".',
            'Penilaian tubuh Anda tidak berhasil, silakan tinjau  laporan komposisi tubuh.',
            'Pengukuran komposisi tubuh tidak berhasil, silakan coba lagi pada perangkat.',
            'Pengukuran Anda tidak berhasil, silakan pergi ke  perangkat dan uji lagi~',
            'Pengukuran komposisi tubuh tidak berhasil, silakan  pergi ke perangkat dan uji lagi~',
            'Penilaian postur tidak berhasil, silakan pergi ke  perangkat dan uji lagi.~',
        ],
        btn: {
            viewReport: 'Lihat Laporan',
            bodyReport: 'Lihat Laporan Komposisi Tubuh',
            postureReport: 'Lihat Laporan Postur Tubuh',
            lastReport: 'Lihat Laporan Terakhir',
            loading: 'Memuat...',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Unduh Laporan',
            send: 'Kirim Laporan',
            report: 'Laporan',
        },
        reportSuccess: 'Berhasil!',
        tabPane: {
            labelList: ['Waktu Pengukuran', 'Item', 'Komposisi Tubuh', 'Penilaian Postur', 'Fungsi Bahu'],
        },
        sendEmail: 'Email:',
        requiredMsg: 'Masukkan alamat email Anda',
        patternMsg: 'Silakan masukkan alamat email yang valid',
        send: 'Kirim',
        download: 'Unduh Laporan',
        sendReport: 'Kirim Laporan',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Perubahan jenis kelamin berhasil',
            height: 'Perubahan tinggi berhasil',
            age: 'Perubahan usia berhasil',
        },
        logOut: 'Keluar',
        personal: 'Pusat Pribadi',
        cut: 'Ubah Unit/Bahasa',
        changeSort: 'Peralihan Laporan Produk',
        describe: '* Setelah mengganti model produk, semua laporan  pengukuran di bawah model ini akan ditampilkan.',
        describeBtn: 'Setelah mengganti model produk, Anda akan dialihkan ke  laporan pengukuran terbaru di bawah model ini.',
        descReport: 'Peralihan Laporan Produk Berhasil',
        unit: ['Metrik (kg, cm)', 'Imperial (ft, in, lb)'],
        read: {
            reading: 'Baca',
            privacy: '(Kebijakan Privasi)',
        },
        setting: 'Pengaturan',
        form: {
            labelList: ['Telepon', 'Unit', 'Bahasa', 'Nama', 'Jenis Kelamin', 'Tinggi Badan', 'Usia'],
        },
        placeholder: ['Ubah Nama Pengguna Anda', 'Jenis Kelamin Baru', 'Tinggi Diperbarui', 'Usia Diperbarui'],
        btn: {
            cancelBtn: 'Batal',
            accomplishBtn: 'Selesai',
            confirmBtn: 'Konfirmasi',
        },
        dialog: [
            'Jenis kelamin adalah salah satu dasar penting untuk  pengukuran komposisi tubuh. Data yang diukur setelah modifikasi akan berbeda  dari data sebelumnya. Harap konfirmasi jika Anda ingin mengubahnya?',
            'Tinggi adalah salah satu dasar penting untuk pengukuran  komposisi tubuh. Data yang diukur setelah modifikasi akan berbeda dari data  sebelumnya. Harap konfirmasi jika Anda ingin mengubahnya?',
            'Usia adalah salah satu dasar penting untuk pengukuran  komposisi tubuh. Data yang diukur setelah modifikasi akan berbeda dari data  sebelumnya. Harap konfirmasi jika Anda ingin mengubahnya?',
        ],
        rules: {
            metricheight: [`Harap isi rentang tinggi yang benar (2'  3" hingga 6'7")`],
            heightMsg: ['Harap isi tinggi dalam 70cm - 200cm'],
            ageMsg: ['Harap isi usia dalam 10 - 99 tahun.', 'Diisi bukan bilangan bulat, usia hanya bisa bilangan  bulat, silakan isi usia yang benar'],
        },
        heightMsg: 'Silakan Pilih Ulang Tinggi',
        ageMsg: 'Silakan Pilih Ulang Usia',
        ModelBinding: 'Sepertinya seseorang sudah memindai kode tersebut,  silakan ukur lagi!',
        ReportEmpty: {
            title: 'Anda belum memiliki laporan apa pun',
            desc: 'Datang ke pemindai tubuh 3D Visbody dan tes lagi!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Sepertinya seseorang sudah memindai kode tersebut,  silakan ukur lagi!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'Anda belum memiliki laporan apa pun',
        desc: 'Datang ke pemindai tubuh 3D Visbody dan tes lagi!',
        descS30: 'Datang ke pemindai tubuh 3D Visbody dan tes lagi!',
        descM30: 'Datang ke pemindai tubuh 3D Visbody dan tes lagi!',
    },
    // 用户信息
    userInfo: {
        height: 'Tinggi:',
        age: 'Usia:',
        unit: 'tahun',
        weight: 'Berat'
    },
    // 体成分
    mass: {
        title: 'Ikhtisar Penilaian Komposisi Tubuh',
        titles: 'Komposisi Tubuh',
        springFrame: '',
        massFrame: 'Tidak ada data pengukuran untuk hari ini',
        contrast: 'Pilih laporan untuk dibandingkan',
        contrastReport: 'Pilih laporan untuk dibandingkan',
        noRecord: 'Tidak ada catatan',
        score: 'Skor',
        status: 'Status komposisi tubuh Anda saat ini ',
        WT: 'berat',
        PBF: 'persentase lemak',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Nilai pengukuran',
            standardRange: 'Rentang standar',
            comparedWithLastLime: 'Perbandingan',
            comparedNet: 'Dibandingkan Dengan Skor Terakhir',
            current: '本次测量分数',
            WT: 'Berat',
            FFM: 'Berat Tanpa Lemak ',
            BFM: 'Lemak ',
            LM: 'Otot',
            TBW: 'Total Air',
            SM: 'Otot Rangka',
            PROTEIN: 'Protein',
            TM: 'Mineral',
            BMR: 'Metabolisme',
            WHR: 'Rasio Pinggang-Panggul',
            BMI: 'BMI',
            PBF: 'Persentase Lemak',
            VFG: 'Lemak Viseral',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
       
        // 定义解读
        explain: [
            {
                title: 'Berat',
                msg: 'Berat adalah jumlah air tubuh, protein, garam  anorganik, dan lemak tubuh.',
            },
            {
                title: 'Berat Tanpa Lemak',
                msg: 'Massa Tubuh Tanpa Lemak adalah berat badan total tanpa  lemak.',
            },
            {
                title: 'Lemak',
                msg: 'Massa lemak tubuh adalah jumlah lemak subkutan, lemak  visceral dan lemak otot.',
            },
            {
                title: 'Otot',
                msg: 'Massa otot adalah bagian dari jaringan ramping tubuh,  yang mencakup otot rangka, otot polos, dan otot jantung.',
            },
            {
                title: 'Total Air',
                msg: 'Sebagian besar tubuh manusia adalah air dengan jumlah  50%-70% dari berat badan. Dan air tubuh terutama ada di sel-sel manusia dan  cairan tubuh, sebagian besar ada di sel otot.',
            },
            {
                title: 'Otot Rangka',
                msg: 'Massa otot rangka, juga dikenal sebagai otot bergaris,  adalah jenis otot yang melekat pada tulang. Data ini mengandung jumlah Otot  Rangka.',
            },
            {
                title: 'Protein',
                msg: 'Protein adalah zat padat dengan amonia, yang ada di  semua sel tubuh manusia. Ini adalah komponen utama massa otot.',
            },
            {
                title: 'Mineral',
                msg: 'Tubuh manusia terdiri dari materi organik, materi anorganik  dan air. Materi anorganik di sini adalah garam anorganik yang jumlahnya 5%  dari berat badan.',
            },
            {
                title: 'Metabolisme',
                msg: 'Laju Metabolisme Basal adalah total energi yang  dikonsumsi dalam sehari saat tubuh sedang istirahat, tidak dipengaruhi oleh  olahraga, benda fisik, kegelisahan, perubahan suhu eksternal, dll.',
            },
            {
                title: 'Rasio Pinggang-Panggul',
                msg: 'Rasio lingkar pinggang ke pinggul, ini adalah indikator  penting untuk menentukan obesitas sentral.',
            },
            {
                title: 'BMI',
                msg: 'BMI terutama digunakan untuk menilai penampilan  obesitas, dan ini adalah standar umum untuk mengukur kegemukan tubuh.',
            },
            {
                title: 'Persentase Lemak',
                msg: 'Persentase lemak tubuh adalah rasio lemak tubuh  terhadap berat badan.',
            },
            {
                title: 'Lemak Viseral',
                msg: 'Lemak visceral adalah indikator penting untuk  mengevaluasi obesitas tersembunyi.',
            },
            {
                title: 'Cairan Intraseluler',
                msg: 'Cairan Intraseluler: Ini adalah cairan tubuh yang terkandung  dalam sel-sel tubuh dan merupakan komponen dasar protoplasma.',
            },
            {
                title: 'Cairan Ekstraseluler',
                msg: 'Cairan Ekstraseluler: Biasanya merujuk pada cairan tubuh  ekstraselular, termasuk plasma dan cairan interstisial antara pembuluh darah  dan sel-sel jaringan.',
            },
            {
                title: 'Usia Metabolik',
                msg: 'Usia metabolik merujuk pada kinerja tubuh dan fungsi kesehatan yang terkait dengan metabolisme.',
            },
        ],
        // 节段
        segment: {
            fat: 'Lemak segmen',
            muscle: 'Otot segmen',
            right: 'Kanan',
            left: 'Kiri',
            standard: 'Normal',
            lowStandard: 'Di Bawah',
            superStandard: 'Di Atas',
            peel: "(Berat kosong)",
        },
    },
    // 体态
    shape: {
        title: 'Ikhtisar Penilaian Tubuh',
        titles: 'Postur',
        status: 'Kondisi fisik Anda saat ini',
        suggest: 'Saran',
        possibility: ', ada kemungkinan ',
        models: {
            front: 'Depan',
            left: 'Sisi Kiri',
            right: 'Sisi Kanan',
            top: 'Belakang',
            low: 'Di Bawah',
            high: 'Di Atas',
            normal: 'Normal',
        },
        item: {
            deviate: 'Nilai pengukuran',
            result: 'Penjelasan',
            normal: 'Normal',
            abnormal: 'Tidak Normal',
            head: 'Kepala maju',
            headSlant: 'Kepala miring',
            roundShoulderLeft: 'Bahu bulat kiri',
            roundShoulderRight: 'Bahu bulat kanan',
            highLowShoudler: 'Bahu tidak rata',
            pelvis: 'Pergeseran panggul ke depan / ke belakang',
            leftKneeCheck: 'Evaluasi lutut kiri',
            rightKneeCheck: 'Evaluasi lutut kanan',
            leg: 'Bentuk kaki',
            leftLeg: 'Kaki kiri:',
            rightLeg: 'Kaki kanan:',
        },
        // 定义解读
        explain: [
            {
                title: 'Kepala maju',
                msg: 'Sudut antara titik telinga di sisi kiri dan garis yang  menghubungkan tengah leher dan garis median di sisi',
            },
            {
                title: 'Kepala miring',
                msg: 'Sudut antara titik tengah kepala depan dan tengah leher  dan garis tengah depan',
            },
            {
                title: 'Bahu bulat kiri',
                msg: 'Sudut antara garis yang menghubungkan titik tertinggi  di sisi kiri punggung dan garis tangen bahu',
            },
            {
                title: 'Bahu bulat kanan',
                msg: 'Sudut antara garis yang menghubungkan titik tertinggi  di sisi kanan punggung dan tangen ke bahu',
            },
            {
                title: 'Bahu tidak rata',
                msg: 'Jarak vertikal antara titik bahu kiri dan kanan di  belakang',
            },
            {
                title: 'Pergeseran panggul ke depan / ke belakang',
                msg: 'Sudut termasuk garis yang menghubungkan titik tengah  leher, titik tulang pinggul, dan titik pergelangan kaki di sisi kiri',
            },
            {
                title: 'Evaluasi lutut kiri',
                msg: 'Sudut termasuk garis tiga titik yang menghubungkan  titik tulang pinggul, sendi lutut, dan titik pergelangan kaki di sisi kiri',
            },
            {
                title: 'Evaluasi lutut kanan',
                msg: 'Sudut termasuk garis tiga titik yang menghubungkan  titik tulang pinggul, sendi lutut, dan titik pergelangan kaki di sisi kanan',
            },
            {
                title: 'Bentuk kaki',
                msg: 'Sudut termasuk garis yang menghubungkan titik tulang  pinggul, sendi lutut, dan titik pergelangan kaki di bagian depan kaki kiri  dan kanan',
            },
        ],
        exception: [
            {
                title: 'Miring ke Sisi Kiri',
                abnormal: '',
            },
            {
                title: 'Miring ke Sisi Kanan',
                abnormal: '',
            },
            {
                title: 'Kiri Tinggi',
                abnormal: '',
            },
            {
                title: 'Kanan Tinggi',
                abnormal: '',
            },
            {
                title: 'Kemiringan Panggul Posterior',
                abnormal: '',
            },
            {
                title: 'Kemiringan Panggul Anterior',
                abnormal: '',
            },
            {
                title: 'Hyperextension Lutut Kiri',
                abnormal: '',
            },
            {
                title: 'Lutut Kiri Membengkok ke Depan',
                abnormal: '',
            },
            {
                title: 'Hyperextension Lutut Kanan',
                abnormal: '',
            },
            {
                title: 'Lutut Kanan Membengkok ke Depan',
                abnormal: '',
            },
            {
                title: 'Kaki Bentuk-K',
                abnormal: '',
            },
            {
                title: 'Kaki Bentuk-D',
                abnormal: '',
            },
            {
                title: 'Kaki Bentuk-X',
                abnormal: '',
            },
            {
                title: 'Kaki Bentuk-O',
                abnormal: '',
            },
        ],
    },
    // 围度信息
    girth: {
        title: 'Lingkaran',
        present: '(Saat Ini)',
        noHistory: 'Tidak ada data',
        tpl: [
            {
                title: 'Lingkar leher',
                key: 'neckGirth',
            },
            {
                title: 'Lingkar lengan atas kiri',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Lingkar lengan atas kanan',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Lingkar dada',
                key: 'bustGirth',
            },
            {
                title: 'Lingkar pinggang tinggi',
                key: 'waistGirth',
            },
            {
                title: 'Lingkar pinggang tengah',
                key: 'midWaistGirth',
            },
            {
                title: 'Lingkar pinggul',
                key: 'hipGirth',
            },
            {
                title: 'Lingkar paha kiri',
                key: 'leftThighGirth',
            },
            {
                title: 'Lingkar bagian tengah paha kiri',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Lingkar paha kiri terkecil',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Lingkar paha kanan',
                key: 'rightThighGirth',
            },
            {
                title: 'Lingkar bagian tengah paha kanan',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Lingkar paha kanan terkecil',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Lingkar betis kiri',
                key: 'leftCalfGirth',
            },
            {
                title: 'Lingkar betis kanan',
                key: 'rightCalfGirth',
            },
            {
                title: 'Pinggang Rendah',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Fungsi Bahu',
        model: {
            val: 'Nilai pengukuran',
            scope: 'Lokasi Aktivitas Maksimum',
            diff: 'Perbandingan',
        },
        normal: 'Tidak terdeteksi kelainan yang patut diperhatikan.',
        normalAll: 'Tidak ada kelainan signifikan dalam fungsi bahu yang terdeteksi.',
        conclusion: 'Kesimpulan',
        analyse: 'Analisis: ',
        suggest: 'Saran: ',
        cause: 'Silakan minta saran dari ahli untuk detailnya.',
    },
    reportTips: {
        title: 'Silakan hubungi administrator jika Anda ingin menghapus  laporan.',
        btnMsg: 'OK',
        alaryTips: 'Laporan ini telah dihapus oleh administrator. Akan  melompat ke laporan berikutnya!',
    },
    saveQr: {
        title: 'Laporan Pemindai Tubuh 3D Visbody',
        btnMsg: 'Tekan lama gambar untuk menyimpannya ke telepon Anda.',
        tips: 'Jika Anda merasa tidak dapat menyimpan, silakan pergi  ke [Pengaturan] dan buka izin yang sesuai.',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthTitle: function monthTitle(year, month) {
          return year + "/" + month;
        },
        rangePrompt: function rangePrompt(maxRange) {
          return "Choose no more than " + maxRange + " days";
        }
      },
    share: {
        title: 'Silakan copy tautan di bawah ini dan bagikan.',
        btnMsg: 'Salin',
    },
    ...idLocale,
    ...agrEn
}
