<!--
 * @Description: 
 * @Author: fanyunbo
 * @Date: 2021-11-15 10:40:49
 * @LastEditTime: 2022-12-06 15:16:21
 * @LastEditors: liutq
 * @Reference: 
-->
<template>
    <div id="app">
        <router-view v-if="isAlive" />
    </div>
</template>

<script>
export default {
    name: 'App',
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isAlive: true
        }
    },
    created() {
        console.log('ver:', process.env.VERSION)
    },
    methods: {
        reload() {
            this.isAlive = false
            this.$nextTick(function() {
                this.isAlive = true
            })
        }
    }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 60px; */
    width: 100%;
    height: 100%;
}
.mint-toast{
    background: rgba(0, 0, 0, 0)!important;
}
a{
    text-decoration: none;
}
</style>
