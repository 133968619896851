/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 11:45:44
 * @LastEditors: liutq
 * @LastEditTime: 2024-05-21 14:53:58
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 重写router的push方法
// Beacuse the old `push` method may throw an Error
const cachePush = VueRouter.prototype.push
const cacheReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return cachePush.call(this, location, onResolve, onReject)
    }
    return cachePush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return cacheReplace.call(this, location, onResolve, onReject)
    }
    return cacheReplace.call(this, location).catch((err) => err)
}
const routes = [
    // H5主入口
    {
        path: '/',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/mainEntry').then(resolve)
        }
    },
    // 扫码绑定入口
    {
        path: '/bind',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/qrCodeEntry.vue').then(resolve)
        }
    },
    // 历史报告入口
    {
        path: '/history',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/historyEntry.vue').then(resolve)
        }
    },
    {
        // 用户登录页面
        path: '/login',
        component: resolve => {
            import('@/pages/login/Index.vue').then(resolve)
        },
        beforeEnter: (to, from, next) => {
            if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'null') {
                next({ path: to.query.redirect || '/' })
            } else {
                next()
            }
        }
    },
    {
        // 用户注册页面
        path: '/register',
        component: resolve => {
            import('@/pages/register/Index.vue').then(resolve)
        },
        beforeEnter: (to, from, next) => {
            if (from.fullPath !== '/about/rule' && from.fullPath !== '/about/secret') {
                window.sessionStorage.removeItem('lookUserInfo')
            }
            next()
        }
    },
    // 综合报告页面en_us
    {
        name: 'report',
        path: '/report',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/report/Index').then(resolve)
        }
    },
    // 综合报告页面
    {
        name: 'report',
        path: '/report',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/report/Index').then(resolve) 
        }
    },
    // 发送报告页面
    {
        path: '/report/send',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/report/send.vue').then(resolve)
        }
    },
    {
        // 模型生成过渡页面
        path: '/modeling',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/modelGenerate/Index.vue').then(resolve)
        },
        beforeEnter: (to, from, next) => {
            // 判断是否从绑定页面跳转过来
            if (from.path !== '/bind' && from.path !== '/') {
                next({path: '/'})
            } else {
                next()
            }
            
        }
    },
    {
        // 用户隐私协议
        path: '/about/secret',
        name: 'UserSecret',
        component: resolve => {
            import('@/pages/sidebar/about/UserSecret.vue').then(resolve)
        }
    },
    {
        // 无测量报告
        path: '/exception/report',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/exception/ReportEmpty.vue').then(resolve)
        }
    },
    {
        // 扫码模型已被绑定
        path: '/exception/repeatBinding',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/exception/ModelBinding.vue').then(resolve)
        }
    },
    // 个人中心
    {
        path: '/personal',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/sidebar/user/Info.vue').then(resolve)
        }
    },
    //单位、语言切换
    {
        path: '/switching',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/sidebar/user/switching.vue').then(resolve)
        }
    },
     //产品型号切换
     {
        path: '/productModel',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/sidebar/user/productModel.vue').then(resolve)
        }
    },
    // 体成分趋势页面
    {
        path: '/body/trend',
        component: resolve => {
            import('@/pages/sidebar/trend/Body').then(resolve)
        }
    },
    // 围度趋势页面
    {
        path: '/girth/trend',
        component: resolve => {
            import('@/pages/sidebar/trend/Girth').then(resolve)
        }
    },
    // 历史记录页
    {
        path: '/report/historyPage',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/report/historyPage.vue').then(resolve)
        }
    },
    // 二维码扫描界面
    {
        path: '/qrcode/scan',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/qrcode/index.vue').then(resolve)
        }
    },
    // 二维码保存界面
    {
        path: '/qrcode/save',
        meta: {
            auth: true
        },
        component: resolve => {
            import('@/pages/save-qr/index.vue').then(resolve)
        }
    },
]

const router = new VueRouter({  
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({
        y: 0
    }),
    routes
})

// 拦截导航判断是否登录
router.beforeEach((to, from, next) => {

    if(to.path === "/login"){
        const languagePrefix = 'lang';
        const unitPrefix = 'unit'

        // 临时存储需要保留的数据
        const itemsToKeep = {};

        // 遍历 localStorage 中的所有键
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            // 如果键名包含特定前缀，则将其存储到 itemsToKeep 对象中
            if (key.startsWith(languagePrefix) || key.startsWith(unitPrefix)) {
                itemsToKeep[key] = localStorage.getItem(key);
            }
        }

        // 清空 localStorage
        localStorage.clear();

        // 将需要保留的数据重新添加到 localStorage 中
        for (const key in itemsToKeep) {
            localStorage.setItem(key, itemsToKeep[key]);
        }
        sessionStorage.clear();
    }
    if (to.meta.auth) {
        console.log('路由守卫', to.meta)
        if (!sessionStorage.getItem('token')) {  // 没有登录信息跳转到登录页
            next({
                path: "/login",
                query: { redirect: to.fullPath }  // 'to.fullPath'跳转到登录之前页面的路径
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router